import $ from 'jquery';
import 'js-cookie/src/js.cookie';

// Load standard components.
require.ensure(['./modules'], () => {
  require('./modules');
});

// Load some other assets which were already referenced...?
require.ensure(['./lib/win'], () => {
  require('./lib/win');
});

// Load angular app.
if (document.querySelector('[data-ui-view]')) {
  require.ensure(['./app'], () => {
    require('./app');
  });
}

// Load 36v template assets.
if ($('#old-36volt').length) {
  require.ensure(['./36volt'], () => {
    require('./36volt');
  });
}

// Load one+ template assets.
if ($('#old-oneplus').length) {
  require.ensure(['./one-plus'], () => {
    require('./one-plus');
  });
}
